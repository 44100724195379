<template >
  <div>
    <v-tabs v-model="tab">
      <v-tab>{{ $t("Preview") }}</v-tab>
      <v-tab>{{ $t("Code") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="mt-2">
        <div
          :contenteditable="
            !$helpers.itemNeedsDisable(
              item.ReadOnly,
              needsDisable,
              editItemAdmin
            )
          "
          v-html="item.Value"
          @input="updateTemplate"
        ></div>
      </v-tab-item>
      <v-tab-item class="mt-2">
        <prism-editor
          class="my-editor"
          v-model="item.Value"
          :highlight="highlighter"
          line-numbers
          max-height="200"
          :readonly="readOnlyCheck"
        ></prism-editor>
      </v-tab-item>
    </v-tabs-items>

    <template v-if="$store.getters.editMode">
      <v-row no-gutters class="my-5">
        <v-col cols="12" sm="12" lg="12">
          <v-row no-gutters>
            <v-col
              cols="12"
              :lg="
                item.colWidth < 7
                  ? item.colWidth < 5
                    ? 12
                    : 4
                  : item.colWidth < 10
                  ? 4
                  : 2
              "
            >
              <v-layout column>
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  :label="$t('Column width')"
                  outlined
                  dense
                ></v-text-field>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.ReadOnlyCode"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Readonly code')"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.RemoveCard"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Remove frame')"
                ></v-switch>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
  <script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      timer: null,
      tab: null,
    };
  },
  components: { PrismEditor },

  computed: {
    readOnlyCheck() {
      let isReadOnly = false;
      if (this.editItemAdmin) {
        isReadOnly = false;
      }
      if (this.item.ReadOnlyCode && !this.editItemAdmin) {
        isReadOnly = true;
      }
      if (
        (this.item.ReadOnly || this.needsDisable) &&
        !this.item.ReadOnlyCode
      ) {
        isReadOnly = false;
      }

      return isReadOnly;
    },
    editorLanguage: {
      get: function () {
        if (!this.$i18n.locale()) {
          return "nl";
        }
        return this.$i18n.locale() == "Nl" ? "nl" : "fr_FR";
      },
    },
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.javascript); //returns html
    },
    addToObject(item) {
      if (!this.item.Value) {
        this.item.Value = "";
      }
      this.item.Value += item.Html;
    },
    updateTemplate(event) {
      // Update the template data with the edited content
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.item.Value = event.target.innerHTML;
      }, 800);
    },
  },
};
</script>
    
<style  scoped>
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>
