var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t("Preview")))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Code")))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"mt-2"},[_c('div',{attrs:{"contenteditable":!_vm.$helpers.itemNeedsDisable(
            _vm.item.ReadOnly,
            _vm.needsDisable,
            _vm.editItemAdmin
          )},domProps:{"innerHTML":_vm._s(_vm.item.Value)},on:{"input":_vm.updateTemplate}})]),_c('v-tab-item',{staticClass:"mt-2"},[_c('prism-editor',{staticClass:"my-editor",attrs:{"highlight":_vm.highlighter,"line-numbers":"","max-height":"200","readonly":_vm.readOnlyCheck},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}})],1)],1),(_vm.$store.getters.editMode)?[_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
                ? _vm.item.colWidth < 5
                  ? 12
                  : 4
                : _vm.item.colWidth < 10
                ? 4
                : 2}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","label":_vm.$t('Column width'),"outlined":"","dense":""},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Readonly code')},model:{value:(_vm.item.ReadOnlyCode),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnlyCode", $$v)},expression:"item.ReadOnlyCode"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}})],1)],1)],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }